import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  // IconButton,
  // InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  // TextField,
  Typography,
} from '@mui/material';
// import { DatePicker } from '@mui/x-date-pickers';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Edit as EditIcon } from '@mui/icons-material';
// import EventIcon from '@mui/icons-material/Event';
// import { DatePicker } from '@mui/x-date-pickers';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { LoadingError } from '../../../../components/LoadingError';
import { Breadcrumb } from '../../../../components/layout/Breadcrumb';
import { useAssignment } from '../../api/useAssignment';
import { UserAvatar } from '../../../../components/Avatar';
import { useUserAssignmentsMutation } from '../../api/useUserAssignmentsMutation';
import { ROUTES } from '../../../../routes';
import { useUserAssignments } from '../../api/useUserAssignments';
import { UserContext } from '../../../../contexts/UserContext';
import { ExternalSource } from '../../../../helpers/enums';

function SaveButton({ isLoading, onclick }) {
  return (
    <Button
      variant="contained"
      disabled={isLoading}
      name="save"
      type="submit"
      startIcon={<SaveAsIcon />}
      onClick={onclick}
    >
      {isLoading ? <CircularProgress size={15} sx={{ margin: 0.5 }} /> : 'Publish'}
    </Button>
  );
}

const getClassKey = (baseClass) => baseClass.id.toString();

const checkStudent = (items, classKey, studentId) => {
  if (!items[classKey].includes(studentId)) {
    items[classKey].push(studentId);
  }
};

const uncheckStudent = (items, classKey, studentId) => {
  const index = items[classKey].indexOf(studentId);
  if (index !== -1) {
    items[classKey].splice(index, 1);
  }
};

const toggleCheckedItem = (prevCheckedItems, baseClass, studentId) => {
  const classKey = getClassKey(baseClass);
  const checked = prevCheckedItems[classKey].includes(studentId);
  const newCheckedItems = { ...prevCheckedItems };
  if (checked) {
    uncheckStudent(newCheckedItems, classKey, studentId);
  } else {
    checkStudent(newCheckedItems, classKey, studentId);
  }

  return newCheckedItems;
};

export function AssignmentAssignPage() {
  const { assignmentId } = useParams();
  const [selectedCourses, setSelectedCourses] = useState(false);
  const [checkedStudents, setCheckedStudents] = useState({});
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const isEdlinkUser = user.externalSource === ExternalSource.EDLINK;  // TODO: Move to UserContext or create dedicated hook

  // TODO: Get it via the main hook instead additional request here
  const {
    data: assignment, // TODO: In practice I need it only to display title in Breadcrumb - consider refactoring
    isLoading: isLoadingAssignment,
    isError: isErrorAssignment,
    error: errorAssignment,
  } = useAssignment(assignmentId);

  const { data, isLoading, isError, error } = useUserAssignments(assignmentId);

  useEffect(() => {
    if (!isLoading && data) {
      const initialCheckedStudents = {};
      data.courses.forEach((course) => {
        const classKey = getClassKey(course);
        initialCheckedStudents[classKey] = [];
        course.courseUsers.forEach((courseUser) => {
          if (data.assignedUserIds.includes(courseUser.user.id)) {
            initialCheckedStudents[classKey].push(courseUser.user.id);
          }
        });
      });
      setCheckedStudents(initialCheckedStudents);
    }
  }, [data]);

  const userAssignmentsMutation = useUserAssignmentsMutation(assignment?.id, {
    // eslint-disable-next-line no-unused-vars
    onSuccess: (result) => {
      navigate(ROUTES.showAssignment(assignment.id));
    },
    onError: (result) => {
      console.log('User Assignment error', result);
    },
  });

  if (isLoadingAssignment || isLoading) {
    return <LoadingIndicator />;
  }

  if (isErrorAssignment || isError) {
    return <LoadingError error={errorAssignment || error} />;
  }

  const handleToggleAll = (course, isAllChecked) => {
    const courseId = course.id;
    setSelectedCourses((prevState) => ({ ...prevState, [courseId]: !prevState[courseId] }));

    course.courseUsers.forEach((courseUser) => {
      setCheckedStudents((prevCheckedStudents) => {
        if (isAllChecked) {
          checkStudent(prevCheckedStudents, getClassKey(course), courseUser.user.id);
        } else {
          uncheckStudent(prevCheckedStudents, getClassKey(course), courseUser.user.id);
        }
        return prevCheckedStudents;
      });
    });
  };

  const handleToggle = (baseClass, studentId) => {
    setCheckedStudents((prevCheckedStudents) => toggleCheckedItem(prevCheckedStudents, baseClass, studentId));
  };

  const handleSave = (e) => {
    e.preventDefault();
    userAssignmentsMutation.mutate({ data: checkedStudents });
  };

  return (
    <Container>
      <Breadcrumb assignmentId={assignmentId} assignmentTitle={assignment.title} />
      <Paper sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          {data.courses.map((course) => (
            <Grid item xs={12} sm={6} md={4} key={course.name}>
              <Typography variant="h5" paddingLeft="15px">
                {course.name}
              </Typography>
              <FormControlLabel
                sx={{ paddingLeft: '15px' }}
                control={
                  <Checkbox
                    checked={selectedCourses[course.id] || false}
                    onChange={(event) => handleToggleAll(course, event.target.checked)}
                  />
                }
                label="Select All"
              />
              <List dense style={{ width: 'fit-content' }}>
                {course.courseUsers.map((courseUser) => {
                  const labelId = `checkbox-list-secondary-label-${courseUser.user.name}`;
                  const checked = checkedStudents[getClassKey(course)]?.includes(courseUser.user.id) || false;
                  return (
                    <ListItem
                      key={courseUser.user.id}
                      disablePadding
                      style={{ paddingTop: '2px', paddingBottom: '2px' }}
                    >
                      <ListItemButton onClick={() => handleToggle(course, courseUser.user.id)}>
                        <Checkbox
                          edge="start"
                          style={{ paddingTop: '2px', paddingBottom: '2px' }}
                          checked={checked}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                        <ListItemAvatar style={{ minWidth: 'auto', width: '32px' }}>
                          <UserAvatar alt={courseUser.user.name} src={courseUser.user.avatarUrl} />
                        </ListItemAvatar>
                        <ListItemText id={labelId} primary={courseUser.user.name} />
                      </ListItemButton>
                      {/* {checkedItems[courseUser.user.id] &&
                          <DatePicker
                            // label="Due date"
                            // onChange={handleDueDateChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // error={!isEmpty(formErrors.dueDate)}
                                // helperText={formErrors.dueDate}
                                name="dueDate"
                                size="small"
                                variant="standard"
                                style={{ width: '130px' }}
                                // InputProps={{
                                  // disableUnderline: true,
                                  //   endAdornment: (
                                  //     <InputAdornment position="end">
                                  //       <IconButton edge="end" style={{ padding: '0 3px 0 3px' }}>
                                  //         <EventIcon style={{ fontSize: '1rem' }} />
                                  //       </IconButton>
                                  //     </InputAdornment>
                                  //   ),
                                // }}
                              />
                            )}
                            // shouldDisableDate={dueDateValidation}
                            value={assignment.dueDate}
                          />
                        } */}
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingY: 2, gap: 2 }}>
          <Button to={ROUTES.editAssignment(assignmentId)} icon={<EditIcon />} variant="outlined">
            Back to Edit
          </Button>
          <SaveButton isLoading={userAssignmentsMutation.isLoading} onclick={(e) => handleSave(e)} />
        </Box>
        {isEdlinkUser && (
          <p style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Typography variant="caption" color="text.secondary">
              Note: If you unassign students, it will only apply at the WordPath application level. You’ll need to
              manually do the same at the LMS level.
            </Typography>
          </p>
        )}
      </Paper>
    </Container>
  );
}
