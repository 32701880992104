import { Box, Button, Stack, TextField, Typography } from '@mui/material';
// import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { API_BASE_URL } from '../../config';

export function EmailSignUp() {
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(true);

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(e.target);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setIsValid(emailRegex.test(value));
  };


  const textFieldRef = useRef(null);
  // const [initFocusChanged, setInitFocusChanged] = useState(false);

  // const handleBrowserAutoFocus = () => {
  //   if (initFocusChanged) {
  //     return;
  //   }
  //   setInitFocusChanged(true);
  // };

  // useEffect(() => {
  //   textFieldRef.current.blur();
  // }, [initFocusChanged]);

  return (
    <Box
      as="form"
      method="post"
      action={`${API_BASE_URL}/auth/login/code`}
      // onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
        <Typography variant="h6" style={{ marginRight: '5px' }}>
          With Email
        </Typography>
        <TextField
          name="email"
          label="Your Email"
          disabled
          onChange={(e) => handleEmailChange(e.target.value)}
          InputLabelProps={{
            sx: {
              textAlign: email ? 'left' : 'center',
              width: '100%',
              transformOrigin: email ? 'left' : 'center',
              top: email ? '-3px' : '0',
            },
          }}
          size="small"
          inputRef={textFieldRef}
          sx={{
            width: 165,
            '& input': { textAlign: 'center' },
          }}
        />
        <Button type="submit" variant="outlined" disabled>
          Sign Up
        </Button>
      </Stack>
      {!isValid && <span style={{ color: 'red' }}>Invalid email format</span>}
    </Box>
  );
}
