import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { withSentryReactRouterV6Routing } from '@sentry/react';
import { ROUTES } from './routes';

import { PromptsConfigurationPage } from './pages/admin/promptsConfigurations/Page';
import { AdminUsersPage } from './pages/admin/users/Page';
// import { TeacherDashboardPage } from './pages/dashboard/teacher/Page';
import { ClassNewPage } from './pages/classes/new/Page';
import { ClassEditPage } from './pages/classes/[id]/edit/Page';
import { DrawerLayout } from './pages/Layout';
import { HomePage } from './pages/home/Page';
import DebugPage from './pages/admin/debug/Page';
import ProtectedRoute from './components/ProtectedRoute';
import { AssignmentAnalyticsPage } from './pages/assignments/[id]/analytics/Page';
import { StudentDashboardPage } from './pages/dashboard/student/Page';
import { AssignmentSubmissionPage } from './pages/assignments/[id]/submission/Page';
import { AddUser } from './pages/admin/users/AddUser';
import { EditUser } from './pages/admin/users/EditUser';
import { ProfilePage } from './pages/profile/Page';
import { TemplatesPage } from './pages/templates/Page';
import { AddTemplate } from './pages/templates/AddTemplate';
import { AssignmentsPage } from './pages/assignments/Page';
import { ClassesIndexPage } from './pages/classes/Page';
import { StudentPage } from './pages/classes/[id]/students/[id]/Page';
import { ClassPage } from './pages/classes/[id]/Page';
import { AssignmentIndexPage } from './pages/assignments/[id]/Page';
import { AssignmentEditPage } from './pages/assignments/[id]/edit/Page';
import { AssignmentNewPage } from './pages/assignments/new/Page';
import { AssignmentAssignPage } from './pages/assignments/[id]/assign/Page';
import { AssignmentTemplatePage } from './pages/assignments/[id]/template/Page';
import { EditTemplate } from './pages/templates/EditTemplate';
import { AssignmentWritePage } from './pages/assignments/[id]/write/Page';
import NotFoundPage from './pages/404/Page';
import ProfileSyncPage from './pages/profile/sync/Page';
import IntegrationsPage from './pages/admin/edlink/Page';
import ErrorPage from './pages/error/Page';
import SignUpPage from './pages/sign-up/Page';

export default function AppRoutes() {
  const SentryRoutes = withSentryReactRouterV6Routing(Routes);

  return (
    <BrowserRouter>
      <SentryRoutes>
        <Route path={ROUTES.HOME} element={<DrawerLayout />}>
          <Route index element={<HomePage />} />
          <Route path={ROUTES.ERROR} element={<ErrorPage />} />
          <Route path={ROUTES.SIGN_UP} element={<SignUpPage />} />
          <Route element={<ProtectedRoute />}>
            <Route path={ROUTES.PROFILE} element={<ProfilePage />} />
            <Route path={ROUTES.PROFILE_DATA_SYNC} element={<ProfileSyncPage />} />

            <Route path={ROUTES.ASSIGNMENTS_INDEX} element={<AssignmentsPage />} />
            <Route path={ROUTES.ASSIGNMENTS_SHOW} element={<AssignmentIndexPage />} />
            <Route path={ROUTES.ASSIGNMENTS_NEW} element={<AssignmentNewPage />} />
            <Route path={ROUTES.ASSIGNMENTS_EDIT} element={<AssignmentEditPage />} />
            <Route path={ROUTES.ASSIGNMENTS_WRITE} element={<AssignmentWritePage />} />
            <Route path={ROUTES.ASSIGNMENTS_ASSIGN} element={<AssignmentAssignPage />} />
            <Route path={ROUTES.ASSIGNMENTS_TEMPLATE} element={<AssignmentTemplatePage />} />
            <Route path={ROUTES.ASSIGNMENTS_SUBMISSION} element={<AssignmentSubmissionPage />} />
            <Route path={ROUTES.ASSIGNMENTS_ANALYTICS} element={<AssignmentAnalyticsPage />} />

            <Route path={ROUTES.TEMPLATES_INDEX} element={<TemplatesPage />} />
            <Route path={ROUTES.TEMPLATES_NEW} element={<AddTemplate />} />
            <Route path={ROUTES.TEMPLATES_EDIT} element={<EditTemplate />} />

            <Route path={ROUTES.CLASSES_INDEX} element={<ClassesIndexPage />} />
            <Route path={ROUTES.CLASSES_SHOW} element={<ClassPage />} />
            <Route path={ROUTES.CLASSES_EDIT} element={<ClassEditPage />} />
            <Route path={ROUTES.CLASSES_NEW} element={<ClassNewPage />} />
            <Route path={ROUTES.CLASSES_STUDENT} element={<StudentPage />} />

            <Route path={ROUTES.ADMIN_PROMPTS_CONFIGURATIONS} element={<PromptsConfigurationPage />} />
            <Route path={ROUTES.ADMIN_USERS} element={<AdminUsersPage />} />
            <Route path={ROUTES.ADMIN_USERS_NEW} element={<AddUser />} />
            <Route path={ROUTES.ADMIN_USERS_EDIT} element={<EditUser />} />
            <Route path={ROUTES.ADMIN_DEBUG} element={<DebugPage />} />
            <Route path={ROUTES.ADMIN_EDLINK} element={<IntegrationsPage />} />

            <Route path={ROUTES.STUDENT_DASHBOARD} element={<StudentDashboardPage />} />
            <Route path={ROUTES.STUDENT_CLASS} element={<StudentDashboardPage />} />
            <Route path={ROUTES.STUDENT_WRITE} element={<AssignmentWritePage />} />
            <Route path={ROUTES.STUDENT_SUBMISSION} element={<AssignmentSubmissionPage />} />

            {/* <Route path={ROUTES.DASHBOARD_TEACHER} element={<TeacherDashboardPage />} /> */}
          </Route>
          <Route path="*" element={<NotFoundPage error="Page Not Found" />} />
        </Route>
      </SentryRoutes>
    </BrowserRouter>
  );
}
