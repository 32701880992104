export const ExternalSource = {
  GOOGLE: 'google',
  EDLINK: 'edlink',
  // cognito: 'cognito' - at the moment we don't need it
};

export const Grades = {
  FIRST_GRADE: '01',
  SECOND_GRADE: '02',
  THIRD_GRADE: '03',
  FOURTH_GRADE: '04',
  FIFTH_GRADE: '05',
  SIXTH_GRADE: '06',
  SEVENTH_GRADE: '07',
  EIGHTH_GRADE: '08',
  NINTH_GRADE: '09',
  TENTH_GRADE: '10',
  ELEVENTH_GRADE: '11',
  TWELFTH_GRADE: '12',
  GRADE_THIRTEEN: '13',
  POSTSECONDARY: 'PS',
  ADULT_LEARNING: 'AL',
  CONTINUED_LEARNING: 'CL',
  UNGRADED: 'UG',
};

export const UserAssignmentStatus = {
  CREATED: 'CREATED',
  PUBLISHED: 'PUBLISHED',
  STARTED: 'STARTED', // TODO: not fully implemented yet
  SUBMITTED: 'SUBMITTED',
};

// TODO: Use this enum
// export const UserRoles = {
//   STUDENT: 'student',
//   TEACHER: 'teacher',
//   ADMIN: 'admin',
// };
