import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ShareIcon from '@mui/icons-material/Share';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { DatePicker } from '@mui/x-date-pickers';
import { isEmpty, isNil } from 'ramda';
import { startOfDay, addDays } from 'date-fns';
import { ROUTES } from '../../../routes';
import { useAssignmentMutation } from '../api/useAssignmentMutation';
import { AssignmentType } from '../../../helpers/assignment';
// import { GradesMenuItems } from '../../../components/GradesMenuItems';
import { ExternalSource, Grades } from '../../../helpers/enums';
import { capitalizeWords } from '../../../helpers/string';
import { UserContext } from '../../../contexts/UserContext';

const VALIDATION_ERRORS = {
  requiredDueDate: 'Due date is required',
  wrongDueDate: 'Please choose a due date in the future',
  instructions: 'Please enter the instructions',
  title: 'Please provide a title for this assignment',
};

const NO_ERRORS = {
  dueDate: '',
  instructions: '',
  title: '',
  grade: '',
};

const NEXT_ASSIGN = 'Assign Students';
const NEXT_TEMPLATE = 'Select Template';

const buildDueDateRestrictionCheck = (currentDate) => {
  const tomorrow = startOfDay(addDays(new Date(), 1));
  const assignmentDueDate = currentDate ? new Date(currentDate) : tomorrow;

  return (value) => {
    try {
      const date = value instanceof Date ? value : new Date(value);
      return date < tomorrow && date.toISOString() !== assignmentDueDate.toISOString()
    } catch (e) {
      return !(e instanceof RangeError && e.message === 'invalid date');
    }
  };
};

// TODO: There are some changes between new VS edit page - consider refactoring
export function AssignmentForm({ assignment = {} }) {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState(assignment);
  const [formErrors, setFormErrors] = useState(NO_ERRORS);
  const [isSaved, setIsSaved] = useState(false);
  const [assignmentId, setAssignmentId] = useState(assignment?.id);
  const [assignmentTypeValue, setAssignmentTypeValue] = useState(assignment?.type || AssignmentType.WRITING);
  const nextButtonLabel = assignmentTypeValue === AssignmentType.WRITING ? NEXT_ASSIGN : NEXT_TEMPLATE;
  const assignmentMutation = useAssignmentMutation(assignment?.id, {
    onSuccess: (result) => {
      setIsSaved(true);
      setAssignmentId(result.assignment.id);
    },
  });
  const dueDateValidation = buildDueDateRestrictionCheck(assignment.dueDate);
  const sessionUser = useContext(UserContext);
  const isDueDateRequired = sessionUser.externalSource === ExternalSource.EDLINK

  const validateForm = (values) => {
    const errors = { ...NO_ERRORS };

    if (isEmpty(values.title)) {
      errors.title = VALIDATION_ERRORS.title;
    }

    if (isEmpty(values.instructions)) {
      errors.instructions = VALIDATION_ERRORS.instructions;
    }

    if(isDueDateRequired && isNil(values.dueDate)) {
      errors.dueDate = VALIDATION_ERRORS.requiredDueDate;
    } else if (dueDateValidation(values.dueDate)) {
      errors.dueDate = VALIDATION_ERRORS.wrongDueDate;
    }

    setFormErrors(errors);

    return !Object.values(errors).some(Boolean);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const replaceEmptyStringsWithNull = (obj) =>
      Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, value === '' ? null : value]));
    const formDataEntries = Object.fromEntries(formData);
    const values = replaceEmptyStringsWithNull(formDataEntries);

    if (assignmentTypeValue === AssignmentType.WRITING) {
      values.templateId = null;
    }

    if (validateForm(values)) {
      assignmentMutation.mutate({
        assignment: { ...values, useGwen: Boolean(values.useGwen), generateImage: Boolean(values.generateImage) },
      });
    }
  };

  const handleFieldChange = (e) => {
    setFormErrors((currentErrors) => {
      if (!isEmpty(currentErrors[e.target.name])) {
        return {
          ...currentErrors,
          [e.target.name]: '',
        };
      }

      return currentErrors;
    });
  };

  const handleDueDateChange = (newValue) => {
    setFormErrors((currentErrors) => ({
      ...currentErrors,
      dueDate: newValue && dueDateValidation(newValue) ? VALIDATION_ERRORS.dueDate : NO_ERRORS.dueDate,
    }));
    setFormValues((values) => ({
      ...values,
      dueDate: newValue,
    }));
  };

  const handleAssignmentTypeChange = (e) => {
    setAssignmentTypeValue(e.target.value);
  };

  const goToNextStep = () => {
    const nextPage =
      assignmentTypeValue === AssignmentType.WRITING
        ? ROUTES.assignToAssignment(assignmentId)
        : ROUTES.editAssignmentTemplate(assignmentId);
    navigate(nextPage);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <FormControl component="fieldset" style={{ marginBottom: '20px' }}>
        <Typography variant="h4" marginBottom={2}>
          What type of assignment would you like to create?
        </Typography>
        <RadioGroup
          aria-label="Assignment type"
          defaultValue={formValues.type || AssignmentType.WRITING}
          name="type"
          onChange={handleAssignmentTypeChange}
        >
          <FormControlLabel
            value={AssignmentType.WRITING}
            control={<Radio />}
            label="WRITING - A single writing assignment of any length"
          />
          <FormControlLabel
            value={AssignmentType.OUTLINE_AND_WRITING}
            control={<Radio />}
            label="OUTLINE & WRITING - A guided outline process followed by writing"
          />
          <FormControlLabel
            disabled
            value={AssignmentType.QUESTIONS_AND_ANSWERS}
            control={<Radio />}
            label="QUESTIONS & ANSWERS - A series of questions that the student answers"
          />
        </RadioGroup>
      </FormControl>
      <TextField
        defaultValue={formValues.title}
        error={!isEmpty(formErrors.title)}
        fullWidth
        helperText={formErrors.title}
        id="assignment-title"
        label="Assignment Title"
        name="title"
        onChange={handleFieldChange}
        variant="outlined"
      />
      <TextField
        defaultValue={formValues.instructions}
        error={!isEmpty(formErrors.instructions)}
        fullWidth
        helperText={formErrors.instructions}
        id="assignment-instructions"
        label="Assignment Instructions"
        maxRows={7}
        minRows={3}
        multiline
        name="instructions"
        onChange={handleFieldChange}
        variant="outlined"
      />
      <TextField
        defaultValue={formValues.grade || 'UNGRADED'}
        error={!isEmpty(formErrors.grade)}
        fullWidth
        helperText={formErrors.grade}
        id="assignment-grade"
        label="Grade"
        name="grade"
        onChange={handleFieldChange}
        select
        variant="outlined"
      >
        {Object.entries(Grades).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {value} - {capitalizeWords(key.replace(/_/g, ' '))}
          </MenuItem>
        ))}
        {/* <GradesMenuItems /> TODO: Use component instead */}
      </TextField>
      <DatePicker
        label={`Due date ${isDueDateRequired ? '' : '(optional)'}`}
        onChange={handleDueDateChange}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!isEmpty(formErrors.dueDate)}
            helperText={formErrors.dueDate}
            name="dueDate"
            style={{ width: '190px' }}
          />
        )}
        shouldDisableDate={dueDateValidation}
        value={formValues.dueDate || null}
      />
      <FormControlLabel
        style={{ marginTop: '15px' }}
        control={
          <Switch defaultChecked={formValues.useGwen === undefined ? true : formValues.useGwen} name="useGwen" />
        }
        label="Enable Gwen AI writing assistance"
      />
      <FormControlLabel
        style={{ marginTop: '15px' }}
        control={
          <Switch
            defaultChecked={formValues.generateImage === undefined ? false : formValues.generateImage}
            name="generateImage"
          />
        }
        label="Enable post writing AI image generation"
      />

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingY: 2, gap: 2 }}>
        <Button
          disabled={assignmentMutation.isLoading}
          name="cancel"
          to={ROUTES.ASSIGNMENTS_INDEX}
          startIcon={<CancelIcon />}
          component={Link}
        >
          Cancel
        </Button>
        <Button
          disabled={assignmentMutation.isLoading || isSaved}
          name="save"
          variant="contained"
          type="submit"
          startIcon={<SaveAsIcon />}
        >
          {assignmentMutation.isLoading ? <CircularProgress size={15} sx={{ margin: 0.5 }} /> : 'Save'}
        </Button>
        <Button
          disabled={!isSaved}
          onClick={goToNextStep}
          name="next"
          type="submit"
          variant="contained"
          sx={{ width: 190 }}
          startIcon={assignmentTypeValue === AssignmentType.WRITING ? <ShareIcon /> : <NavigateNextIcon />}
        >
          {assignmentMutation.isLoading ? <CircularProgress size={15} sx={{ margin: 0.5 }} /> : nextButtonLabel}
        </Button>
      </Box>
    </Box>
  );
}
